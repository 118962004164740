import { create } from 'zustand'

const useGlobal = create(() => ({
  isShowMobileMenu: false,
  successModalContent: false,
  isShowSelectCenterModal: false,
  centerModalType: '',
  simpleModalTitle: null,
  simpleModalDescription: null,
  simpleModalChildren: null,
  simpleModalWrapperClass: '',
  buttonModalDetail: null,
  isShowDoctorFilter: false,
  isShowSearchFilter: false,
  isShowHomepagePopup: false,
  isShowGuestSnackbar: false,
  confirmationDialog: null,
  emailPattern:
    /^[a-zA-Z0-9._%+-]+@(?!gmail\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.vom|\.xom|\.dom|\.som|\.fom)$|^[a-zA-Z0-9._%+-]+@gmail\.com$/,
}))

export default useGlobal
